import React, { useState, memo } from 'react';
import classnames from 'classnames';
import { Link } from 'gatsby';
import Img from 'gatsby-image/withIEPolyfill';
import { useMediaQuery } from 'react-responsive';
import { useInView } from 'react-hook-inview';

import Fadeupintoview from '../FadeUpIntoView/fadeupintoview';

import isOdd from '../../utils/isOdd';

const ProjectGridItem = ({
  index,
  title,
  formattedTitle,
  client,
  category,
  slug,
  image,
  imageHover,
  textColour,
  horizontal,
  home,
  limited,
}) => {
  const [inView, setInView] = useState(limited ? true : false);

  const isTablet = useMediaQuery({ query: '(min-width: 768px)' }, undefined);

  let ref;

  [ref] = useInView({
    threshold: 0.1,
    unobserveOnEnter: true,
    onEnter: () => {
      setInView(true);
    },
  });

  return (
    <article
      className={classnames('project-grid-item', {
        'project-grid-item--horizontal': horizontal,
        'project-grid-item--home': home,
        'project-grid-item--in-view': inView,
        'project-grid-item--limited': limited,
        'project-grid-item--even': isOdd(index),
        'project-grid-item--odd': !isOdd(index),
      })}
      ref={ref}
    >
      <Link
        to={`/work/${slug}/`}
        className="project-grid-item__link"
        data-cursor-text="View"
      >
        <div className="project-grid-item__images">
          <figure className="project-grid-item__image">
            <Img
              fluid={image.fluid}
              fadeIn={false}
              loading="eager"
              alt={`${client}: ${title}`}
              className="project-grid-item__image-el"
            />
          </figure>
          <figure className="project-grid-item__image project-grid-item__image--rollover">
            <Img
              fluid={imageHover.fluid}
              fadeIn={false}
              loading="eager"
              alt={`${client}: ${title} (rollover)`}
              className="project-grid-item__image-el"
            />
          </figure>
        </div>
        <div
          className={classnames('project-grid-item__info', {
            'project-grid-item__info--dark': textColour === 'dark',
            'project-grid-item__info--even': isOdd(index),
            'project-grid-item__info--odd': !isOdd(index),
          })}
          data-cursor-dark={horizontal || home}
        >
          {isTablet && !horizontal && !home && (
            <span className="project-grid-item__category">{category}</span>
          )}

          <div className="project-grid-item__titles">
            {horizontal || home ? (
              <>
                <Fadeupintoview as="h2" extraClass="project-grid-item__client">
                  {client}
                </Fadeupintoview>
                <Fadeupintoview as="h1" extraClass="project-grid-item__title">
                  {category} - {formattedTitle.replace('/', '')}
                </Fadeupintoview>
              </>
            ) : (
              <>
                <h2 className="project-grid-item__client">{client}</h2>
                <h1 className="project-grid-item__title">
                  {!isTablet && (
                    <span className="project-grid-item__category">
                      {category} -{' '}
                    </span>
                  )}
                  {formattedTitle.split('/').map((word) => (
                    <span className="project-grid-item__title-span" key={word}>
                      {word.trim()}
                    </span>
                  ))}
                </h1>
              </>
            )}
          </div>
        </div>
      </Link>
    </article>
  );
};

export default memo(ProjectGridItem);
