import React, { useEffect, useRef, useState, memo } from 'react';
import classnames from 'classnames';
import { Link } from 'gatsby';
import { useMediaQuery } from 'react-responsive';
import { useInView } from 'react-hook-inview';
import hoverEffect from 'hover-effect';

import isOdd from '../../utils/isOdd';
import displacementImg from './displacement.jpg';

const EnhancedProjectGridItem = ({
  index,
  title,
  formattedTitle,
  client,
  category,
  slug,
  image,
  hasVideo,
  imageHover,
  horizontalImage,
  horizontalImageHover,
  textColour,
  horizontal,
  home,
  limited,
}) => {
  const [inView, setInView] = useState(limited ? true : false);
  const [isHovered, setIsHovered] = useState(false);
  const [videoPlaying, setVideoPlaying] = useState(false);

  const isTablet = useMediaQuery({ query: '(min-width: 768px)' }, undefined);

  const imgSrc = image.fluid.src;
  const hoverImgSrc = imageHover.fluid.src;

  const imgSrcHorizontal = horizontalImage?.fluid?.src;
  const hoverImgSrcHorizontal = horizontalImageHover?.fluid?.src;

  const videoSrc = hasVideo && `https://wreel.agency/video/grid--${slug}`;

  let refEl;
  const imgEl = useRef(null);
  const videoRef = useRef();

  [refEl] = useInView({
    threshold: 0,
    unobserveOnEnter: true,
    onEnter: () => {
      setInView(true);
    },
  });

  const image1 = imgSrcHorizontal && horizontal ? imgSrcHorizontal : imgSrc;
  const image2 =
    hoverImgSrcHorizontal && horizontal ? hoverImgSrcHorizontal : hoverImgSrc;

  useEffect(() => {
    // eslint-disable-next-line no-unused-vars
    const effect =
      !hasVideo &&
      new hoverEffect({
        parent: imgEl.current,
        intensity: -0.04,
        speedIn: 1,
        speedOut: 1,
        imagesRatio: horizontal ? 1300 / 2882 : 1075 / 1440,
        image1: image1,
        image2: image2,
        displacementImage: displacementImg,
      });
  }, [inView, imgSrc, hoverImgSrc, image1, image2, horizontal, hasVideo]);

  useEffect(() => {
    if (hasVideo && !videoPlaying) {
      videoRef.current.play();
      setVideoPlaying(true);
    }
  }, [inView, videoPlaying, hasVideo]);

  return (
    <article
      className={classnames(
        'project-grid-item',
        'project-grid-item--enhanced',
        {
          'project-grid-item--horizontal': horizontal,
          'project-grid-item--home': home,
          'project-grid-item--enhanced-horizontal': horizontal,
          'project-grid-item--in-view': inView,
          'project-grid-item--limited': limited,
        }
      )}
      ref={refEl}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      role="presentation"
    >
      <div>
        <Link
          to={`/work/${slug}/`}
          className="project-grid-item__link"
          data-cursor-text="View"
        >
          {hasVideo ? (
            <div className="project-grid-item__video-wrap">
              <video
                className="project-grid-item__video"
                loop={true}
                autoPlay={false}
                muted={true}
                volume={0}
                preload="metadata"
                disablePictureInPicture
                poster={`${videoSrc}.jpg`}
                ref={videoRef}
                playsInline
              >
                <source src={`${videoSrc}.mp4`} type="video/mp4" />
                <source src={`${videoSrc}.webm`} type="video/webm" />
              </video>
            </div>
          ) : (
            <div className="project-grid-item__images" ref={imgEl} />
          )}
          <div
            className={classnames('project-grid-item__info', {
              'project-grid-item__info--dark': textColour === 'dark',
              'project-grid-item__info--even': isOdd(index),
              'project-grid-item__info--odd': !isOdd(index),
            })}
            data-cursor-dark={horizontal || home}
          >
            {isTablet && !horizontal && !home && (
              <span className="project-grid-item__category">{category}</span>
            )}
            <div className="project-grid-item__titles">
              {horizontal || home ? (
                <>
                  <h2
                    className={classnames(
                      'project-grid-item__client',
                      'project-grid-item__client--show'
                    )}
                  >
                    {client}
                  </h2>
                  <h1
                    className={classnames('project-grid-item__title', {
                      'project-grid-item__title--show': isHovered,
                    })}
                  >
                    {category} - {formattedTitle.replace('/', '')}
                  </h1>
                </>
              ) : (
                <>
                  <h2 className="project-grid-item__client">{client}</h2>
                  <h1 className="project-grid-item__title">
                    {!isTablet && (
                      <span className="project-grid-item__category">
                        {category} -{' '}
                      </span>
                    )}
                    {formattedTitle.split('/').map((word) => (
                      <span
                        className="project-grid-item__title-span"
                        key={word}
                      >
                        {word.trim()}
                      </span>
                    ))}
                  </h1>
                </>
              )}
            </div>
          </div>
        </Link>
      </div>
    </article>
  );
};

export default memo(EnhancedProjectGridItem);
