import React, { memo } from 'react';
import classnames from 'classnames';
import { useMediaQuery } from 'react-responsive';

import hasMouse from '../../utils/hasMouse';

import EnhancedProjectGridItem from '../EnhancedProjectGridItem';
import ProjectGridItem from '../ProjectGridItem';

const ProjectGrid = ({
  projects,
  limited,
  home,
  inFlow,
  horizontal,
  flip,
  number,
}) => {
  const isDesktop = useMediaQuery({ query: '(min-width: 768px)' }, undefined);

  const ItemEl =
    hasMouse() && isDesktop ? EnhancedProjectGridItem : ProjectGridItem;

  const hasVideo = (slug) =>
    isDesktop && home && slug === 'your-beautiful-reality';

  return (
    <section className="panel panel--project-grid" id="work">
      <ul
        className={classnames('project-grid', {
          'project-grid--in-flow': inFlow,
          'project-grid--home': home,
          'project-grid--home-flip': home && flip,
          'project-grid--horizontal': horizontal,
          [`project-grid--${number}`]: number,
        })}
      >
        {projects.map(
          (
            {
              title,
              formattedTitle,
              client,
              category,
              slug,
              image,
              imageHover,
              horizontalImage,
              horizontalImageHover,
              textColour,
            },
            i
          ) => {
            return (
              <li
                className={classnames('project-grid__item', {
                  'project-grid__item--limited': limited,
                  'project-grid__item--first-two': home,
                  'project-grid__item--in-flow': inFlow,
                  'project-grid__item--horizontal': horizontal,
                })}
                key={`projectGrid-${i}`}
              >
                <ItemEl
                  index={i}
                  hasVideo={hasVideo(slug)}
                  {...{
                    title,
                    formattedTitle,
                    client,
                    category,
                    slug,
                    image,
                    imageHover,
                    horizontalImage,
                    horizontalImageHover,
                    textColour,
                    horizontal,
                    home,
                    limited,
                  }}
                />
              </li>
            );
          }
        )}
      </ul>
    </section>
  );
};

export default memo(ProjectGrid);
